<template>
  <div class="dp-spinner" :style="style" />
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import { colors } from '@/composables/useColors';

export default defineComponent({
  props: {
    size: {
      type: String,
      default: '24px'
    },

    color: {
      type: String,
      default: 'grey05'
    },

    weight: {
      type: String,
      default: '2px'
    }
  },

  setup: (props) => {
    const style = computed(() => {
      return {
        width: props.size,
        height: props.size,
        'border-width': props.weight,
        'border-color': colors.dp[props.color]
      };
    });

    return {
      style
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-spinner {
  margin: 0 auto;
  border-radius: 50%;
  border-style: solid;
  border-left-color: transparent !important;
  animation: spinner 1.75s linear infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
</style>
